import type { SxProps } from "@mui/material";
import { Label } from "@the-source/front-core/components";
import { useMemo, useState } from "react";
import { newLabelDaysThreshold } from "../preset";
import getNumberOfDaysBetweenTwoDates from "../utils/getNumberOfDaysBetweenTwoDates";

type LabelNewProps = {
  date: string;
  sx?: SxProps;
};

export default function LabelNew({ date, sx }: LabelNewProps): JSX.Element {
  const [now] = useState(new Date());
  const isNew = useMemo(() => getNumberOfDaysBetweenTwoDates(new Date(date), now) < newLabelDaysThreshold, [date]);
  return (
    <Label variant="outlined" sx={{ bgcolor: "white", visibility: isNew ? "visible" : "hidden", ...sx }}>
      NEW
    </Label>
  );
}
