import { Skeleton, useTheme } from "@mui/material";
// import { VirtualizedMasonry, VirtualizedMasonryProps } from "@the-source/front-core/components";
import pxValueToString from "../utils/pxValueToString";
import ErrorAlert from "./alerts/ErrorAlert";
import EmptyAlert from "./alerts/EmptyAlert";
import { VirtualizedMasonry, VirtualizedMasonryProps } from "@the-source/front-core/components";

export type MasonryProps = {
  isLoading: boolean;
  error?: boolean;
  dataType: string;
} & Omit<VirtualizedMasonryProps, "placeholder">;

const Masonry = ({ isLoading, error, dataType, children, ...virtualizedMasonryProps }: MasonryProps) => {
  const { spacing } = useTheme();
  if (!isLoading && children.length == 0) return <EmptyAlert dataType="assets" />;
  else if (error) return <ErrorAlert />;
  else
    return (
      <VirtualizedMasonry
        {...virtualizedMasonryProps}
        placeholder={<Skeleton sx={{ bgcolor: "white", transform: "inherit", height: "100%" }} />}
        gap={pxValueToString(spacing(3))}
      >
        {children}
      </VirtualizedMasonry>
    );
};

export default Masonry;
