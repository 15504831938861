import { Platform } from "../graphql/graphql";

import { PlatformTikTok, PlatformMeta } from "@the-source/front-core/assets";

export default function getPlatformIcon(platform: Platform): string {
  switch (platform) {
    case Platform.Facebook:
      return PlatformMeta;
    case Platform.Tiktok:
      return PlatformTikTok;
    default:
      throw new Error(`No platform icon for ${platform}`);
  }
}
