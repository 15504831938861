import { createIconListParameter, renderCheckboxPreviewText } from "@the-source/front-core/components";
import { useContext, useEffect } from "react";
import { FiltersContext, FiltersType, filteredPage } from "../context/filtersContext";
import { Platform } from "../graphql/graphql";
import localization from "../localization";
import getPlatformIcon from "../utils/getPlatformIcon";

const usePlatformFilter = (page: filteredPage, required: boolean) => {
  const { setFieldParameter } = useContext(FiltersContext) as FiltersType;

  useEffect(() => {
    setFieldParameter(
      page,
      createIconListParameter({
        name: "platform",
        title: "Platform",
        required,
        defaultValues: required ? [Platform.Facebook] : [],
        choices: Object.values(Platform).map((platform) => ({
          value: platform,
          display: getPlatformIcon(platform),
        })),
        onPreviewText: (values) =>
          renderCheckboxPreviewText(
            values,
            Object.values(Platform).map((platform) => ({
              value: platform,
              display: localization.platform[platform],
            }))
          ),
      })
    );
  }, []);
};

export default usePlatformFilter;
