import { cloudStoragePrefixHTTP } from "../preset";
import getSignedUrl from "./getSignedUrl";

export default function getFallbackUrl(urls: string[]) {
  const stageLogic = process.env.NEXT_PUBLIC_STAGE ?? "dev";
  if (stageLogic == "dev") {
    const legacyUrls = urls.map((url) => {
      const splittedUrl = url.split("/");
      const env = splittedUrl[3];
      const path = splittedUrl.slice(4).join("/");
      return `${cloudStoragePrefixHTTP}creative-lift-${env}-uploads/${path}`;
    });
    return getSignedUrl(legacyUrls);
  }
  return urls;
}
