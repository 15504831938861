import type { BoxProps } from "@mui/material";
import { Box } from "@mui/material";
import Head from "next/head";

export type PageProps = {
  title?: string;
  header?: {
    content: JSX.Element;
    props?: BoxProps;
    sticky?: boolean;
  };
} & BoxProps;

export default function Page({ title, children, header, ...boxProps }: PageProps): JSX.Element {
  return (
    <>
      {title && (
        <Head>
          <title>{`${title} | Creative lift`}</title>
        </Head>
      )}
      {header?.content && (
        <Box
          px={5}
          py={3}
          bgcolor="white"
          {...header.props}
          {...(header.sticky && { position: "sticky", top: 0, zIndex: 1 })}
        >
          {header.content}
        </Box>
      )}
      <Box {...boxProps} sx={{ p: 5, ...boxProps.sx }}>
        {children}
      </Box>
    </>
  );
}
