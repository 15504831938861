import { IconButton } from "@mui/material";
import { Iconify } from "@the-source/front-core/components";
import { useRouter } from "next/router";

export type BackButtonProps = {
  url?: string;
};

const BackButton = ({ url }: BackButtonProps): JSX.Element => {
  const router = useRouter();
  return (
    <IconButton onClick={url ? () => router.push(url) : () => router.back()} sx={{ mr: "12px" }}>
      <Iconify icon={"akar-icons:arrow-left"} />
    </IconButton>
  );
};

export default BackButton;
