import { useContext, useEffect } from "react";
import { AuthContext, AuthContextType } from "../context/authContext";
import { FiltersContext, FiltersType, filteredPage } from "../context/filtersContext";
import sortByAlphanumericOrder from "../utils/sortByAlphanumericOrder";
import createChoicesFieldParameter from "./createChoicesFieldParameter";

const useIndustryFilter = (page: filteredPage, required: boolean) => {
  const { getUserIndustries } = useContext(AuthContext) as AuthContextType;
  const { setFieldParameter } = useContext(FiltersContext) as FiltersType;

  useEffect(() => {
    const industries = sortByAlphanumericOrder(getUserIndustries(), (industry) => industry.name);
    if (industries.length > 1)
      setFieldParameter(
        page,
        createChoicesFieldParameter({
          name: "industry",
          title: "Industry",
          required,
          defaultValues: [industries[0].id],
          choices: industries.map((industry) => ({
            display: industry.name,
            value: industry.id,
          })),
        })
      );
  }, [JSON.stringify(getUserIndustries())]);
};

export default useIndustryFilter;
