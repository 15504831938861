import { DocumentNode, OperationVariables, useLazyQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import type { FieldParameter } from "@the-source/front-core/components";
import type { Choice } from "@the-source/front-core/components/filters/Filters";
import { useContext, useEffect, useMemo, useState } from "react";
import FilterFieldSkeleton from "../components/loading/FilterFieldSkeleton";
import { FiltersContext, FiltersType, filteredPage } from "../context/filtersContext";
import localization from "../localization";
import getQueryVariablesKeys from "../utils/getQueryVariablesKeys";

function useQueriedFilter<QueryType, VariablesType extends OperationVariables>(
  page: filteredPage,
  fieldParameter: FieldParameter<any, any>,
  queryDocument: DocumentNode,
  selectChoices: (data: QueryType) => Choice<any, any>[],
  ressourceName: string,
  pageVariables?: VariablesType
) {
  const { setFieldParameter } = useContext(FiltersContext) as FiltersType;
  const [lastChoices, setLastChoices] = useState<Choice<any, any>[]>();
  const [fetch, { error }] = useLazyQuery(queryDocument);

  const NoChoicesPlaceholder = () => (
    <Typography variant="body2" color="text.secondary">
      {localization.formatString(localization.filters.no_filter_with_matching_ressources, ressourceName)}
    </Typography>
  );

  const ErrorPlaceHolder = () => (
    <Typography variant="body2" color="error.dark" noWrap>
      {localization.formatString(localization.filters.unable_to_load_filter, ressourceName)}{" "}
      <Typography
        variant="inherit"
        display="inline"
        sx={{ textDecoration: "underline", "&:hover": { cursor: "pointer" } }}
        onClick={() => fetchChoices()}
      >
        Retry
      </Typography>
    </Typography>
  );

  function onPreviewText(values: any[], choices: Choice<any, any>[]) {
    return values.length > 0 ? fieldParameter.onPreviewText(values, choices) : `All ${ressourceName}`;
  }

  function fetchChoices() {
    setFieldParameter(page, {
      ...fieldParameter,
      onPreviewText,
      choices: lastChoices ?? [],
      placeholder: <FilterFieldSkeleton />,
    });
    if (fieldParameter.required || pageVariables) {
      fetch({ variables: pageVariables })
        .then(({ data }) => {
          const choices = selectChoices(data);
          setLastChoices(choices);
          setFieldParameter(page, {
            ...fieldParameter,
            onPreviewText,
            ...(choices.length > 0
              ? { choices, ...(fieldParameter.required && { defaultValues: [choices[0].value] }) }
              : { placeholder: <NoChoicesPlaceholder /> }),
            collapsable: choices.length > 3,
          });
        })
        .catch((_) => {
          setFieldParameter(page, {
            ...fieldParameter,
            onPreviewText,
            placeholder: <ErrorPlaceHolder />,
          });
        });
    }
  }

  function getVariablesEntriesUsedByQuery(pageVariables: VariablesType) {
    return Object.fromEntries(
      Object.entries(pageVariables).filter(([key]) => getQueryVariablesKeys(queryDocument)?.includes(key))
    );
  }

  const queryVariables = useMemo(
    () => (pageVariables ? getVariablesEntriesUsedByQuery(pageVariables) : []),
    [pageVariables ? JSON.stringify(pageVariables) : undefined]
  );

  useEffect(() => {
    fetchChoices();
  }, [page, JSON.stringify(queryVariables)]);

  return { error };
}

export default useQueriedFilter;
