import React, { useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { palette } from "@the-source/front-core/theme";

import { Filters } from "@the-source/front-core/components";
import type { FieldParameter } from "@the-source/front-core/components";
import { FiltersContext, FiltersType } from "../../context/filtersContext";
import BackButton from "../navigation/BackButton";

type Props = {
  title: string;
  subtitle?: string;
  page: string;
  config: FieldParameter<any, any>[];
  activeFilters: any;
  backLink?: boolean;
};

const HeaderWithFilters = ({
  title,
  page,
  config,
  activeFilters,
  subtitle = "",
  backLink = false,
}: Props): JSX.Element => {
  const { setFilter, resetFilters, setInitialUrlFilters } = useContext(FiltersContext) as FiltersType;

  const handleChangeFilters = async (field: string, values: any): Promise<void> => {
    setFilter(page, field, values);
  };

  useEffect(() => setInitialUrlFilters(page), []);

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          {backLink && <BackButton />}
          <Typography variant="h3">{title}</Typography>
        </Box>
        {!!subtitle && (
          <Typography variant="body2" sx={{ color: palette.light.text.secondary }}>
            {subtitle}
          </Typography>
        )}
      </Box>
      {config.length > 0 && (
        <Filters
          onChange={handleChangeFilters}
          onClear={() => resetFilters(page)}
          fields={config}
          values={activeFilters}
        />
      )}
    </>
  );
};

export default HeaderWithFilters;
