import { filteredPage } from "../context/filtersContext";
import { ObjectiveFamiliesDocument, ObjectiveFamiliesQuery, ObjectiveFamiliesQueryVariables } from "../graphql/graphql";
import localization from "../localization";
import { objectiveFamiliesOrder } from "../preset";
import createChoicesFieldParameter from "./createChoicesFieldParameter";
import useQueriedFilter from "./useQueriedFilter";

const useObjectivesFilter = (page: filteredPage, required: boolean, variables?: ObjectiveFamiliesQueryVariables) => {
  const { error } = useQueriedFilter<ObjectiveFamiliesQuery, ObjectiveFamiliesQueryVariables>(
    page,
    createChoicesFieldParameter({
      name: "objective",
      title: "Objective",
      defaultValues: [],
      required,
      choices: [],
    }),
    ObjectiveFamiliesDocument,
    ({ objectiveFamilies }) =>
      objectiveFamiliesOrder.map((objectiveName) => ({
        value: objectiveFamilies.find((objectiveFamily) => objectiveFamily.objectiveFamily == objectiveName)
          ?.id as string,
        display: localization.objective[objectiveName],
      })),
    "objectives",
    variables
  );

  return { error };
};

export default useObjectivesFilter;
