import React from "react";
import { Box, Skeleton } from "@mui/material";

const FilterFieldSkeleton = (): JSX.Element => (
  <>
    {[5, 7, 3].map((width, index) => (
      <Box key={index}>
        <Skeleton variant="rectangular" sx={{ aspectRatio: "1/1", display: "inline-block" }} />
        <Skeleton variant="text" width={`${width}rem`} sx={{ display: "inline-block", marginLeft: 1.5 }} />
      </Box>
    ))}
  </>
);

export default FilterFieldSkeleton;
