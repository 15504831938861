import {
  FieldBaseParameter,
  createCheckboxListParameter,
  createRadioListParameter,
} from "@the-source/front-core/components";

const createChoicesFieldParameter = (fieldParameter: FieldBaseParameter<any, any>) => {
  return fieldParameter.required
    ? createRadioListParameter(fieldParameter)
    : createCheckboxListParameter(fieldParameter);
};

export default createChoicesFieldParameter;
