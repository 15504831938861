import { AlertTitle } from "@mui/material";
import Alert, { AlertProps } from "@mui/material/Alert/Alert";

type ErrorAlertProps = {
  message?: string;
  onClose?: () => void;
} & AlertProps;

export default function ErrorAlert({ message, onClose, ...alertProps }: ErrorAlertProps): JSX.Element {
  return (
    <Alert
      severity="error"
      sx={{ minWidth: "528px", width: "fit-content", ...alertProps.sx }}
      onClose={onClose}
      {...alertProps}
    >
      <AlertTitle>Error</AlertTitle>
      {message ?? (
        <>
          An error has occurred — <strong>Please try again later</strong>
        </>
      )}
    </Alert>
  );
}
