import { AlertTitle } from "@mui/material";
import Alert, { AlertProps } from "@mui/material/Alert/Alert";

type EmptyAlertProps = {
  dataType: string;
  message?: JSX.Element;
} & AlertProps;

export default function EmptyAlert({ dataType, message, ...alertProps }: EmptyAlertProps): JSX.Element {
  return (
    <Alert severity="info" sx={{ minWidth: "528px", width: "fit-content", ...alertProps.sx }} {...alertProps}>
      <AlertTitle>No {dataType}</AlertTitle>
      {message ?? (
        <>
          No {dataType} are available for this selection! - <strong>Please make a wider selection</strong>
        </>
      )}
    </Alert>
  );
}
