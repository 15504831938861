import type { DocumentNode, QueryOptions } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { useEffect, useRef, useState } from "react";

type MasonryKey = {
  key: number;
  groupKey: number;
};

type WithMasonryKey<ItemType> = MasonryKey & ItemType;
type PaginatedVariable = { offset: number; limit: number };

type MasonryQueryProps<QueryDataType, QueryVariableType, ItemType> = {
  itemsPerPage: number;
  pagesPerBatch: number;
  queryOptions: QueryOptions<QueryVariableType, QueryDataType> & { query: DocumentNode; skip?: boolean };
  selectData: (data: QueryDataType) => ItemType[];
};

type MasonryQuery<ItemType> = {
  items: WithMasonryKey<ItemType>[];
  loadPage: (page: number) => Promise<number>;

  isLoading: boolean;
  hasError: boolean;
};

export default function useMasonryQuery<QueryDataType, QueryVariableType, ItemType>({
  itemsPerPage,
  pagesPerBatch,
  queryOptions,
  selectData,
}: MasonryQueryProps<QueryDataType, QueryVariableType, ItemType>): MasonryQuery<ItemType> {
  const [itemPool, setItemPool] = useState<ItemType[]>([]);
  const [items, setItems] = useState<WithMasonryKey<ItemType>[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const abortController = useRef<AbortController>();

  const lastPrefetchQuery = useRef<Promise<ItemType[]>>();

  const apollo = useApolloClient();

  useEffect(() => {
    clearItems();
    lastPrefetchQuery.current = undefined;
    if (abortController.current) {
      abortController.current.abort();
    }
    setIsLoading(true);
    setHasError(false);
    loadPage(0);
  }, [JSON.stringify(queryOptions.variables)]);

  function fetchItems(offset: number, limit: number) {
    abortController.current = new AbortController();
    return new Promise<ItemType[]>((resolve, reject) => {
      apollo
        .query<QueryDataType, QueryVariableType | PaginatedVariable>({
          ...queryOptions,
          variables: { ...queryOptions.variables, offset, limit },
          context: { fetchOptions: { signal: (abortController.current as AbortController).signal } },
        })
        .then((result) => {
          setIsLoading(false);
          if (result.data) resolve(selectData(result.data));
          else reject();
        })
        .catch((error) => {
          if (error.networkError.name == "AbortError") return;
          setIsLoading(false);
          setHasError(true);
        });
    });
  }

  function addKeysToItems(items: ItemType[], page: number) {
    return items.map((item, i) => ({ ...item, key: page * itemsPerPage + i + 1, groupKey: page + 1 }));
  }

  function clearItems() {
    setItems([]);
    setItemPool([]);
  }

  function prefetchBatch(batch: number) {
    lastPrefetchQuery.current = fetchItems(batch * (pagesPerBatch * itemsPerPage), pagesPerBatch * itemsPerPage);
  }

  async function getBatch(batch: number, page: number) {
    const offset = batch * (pagesPerBatch * itemsPerPage);
    const isBatchInPool = itemPool.length > offset;
    if (isBatchInPool && page > 0) return itemPool.slice(offset, offset + itemsPerPage * pagesPerBatch);
    else {
      if (!lastPrefetchQuery.current) prefetchBatch(batch);
      const items = await (lastPrefetchQuery.current as Promise<ItemType[]>);
      setItemPool((previous) => [...previous, ...items]);
      lastPrefetchQuery.current = undefined;
      return items;
    }
  }

  async function getItemsFromPool(page: number) {
    const batch = Math.floor(page / pagesPerBatch);
    const itemsFromPool = await getBatch(batch, page);
    const offset = (page % pagesPerBatch) * itemsPerPage;
    return itemsFromPool.slice(offset, offset + itemsPerPage);
  }

  async function loadPage(page: number) {
    const itemsToAdd: ItemType[] = await getItemsFromPool(page);
    setItems((previous) => [...previous, ...addKeysToItems(itemsToAdd, page)]);
    if (page % pagesPerBatch == 0) prefetchBatch(page / pagesPerBatch + 1);
    return itemsToAdd.length;
  }

  return { items, loadPage, isLoading, hasError };
}
