import { cloudStoragePrefixHTTP, cloudStorageSignedURLEndpoint } from "../preset";

export default function getSignedUrl(urls: string[]): string[] {
  return (urls || []).map((url) => {
    if (url.startsWith(cloudStoragePrefixHTTP)) {
      return `${cloudStorageSignedURLEndpoint}?path=${url}`;
    }
    return url;
  });
}
